.reviewManagement {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--dark-brown);
}

.stats {
  display: flex;
  gap: 16px;
}

.statItem {
  background-color: #f9f9f9;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  min-width: 100px;
}

.statValue {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--dark-brown);
}

.statLabel {
  font-size: 0.85rem;
  color: #666;
}

.filters {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchForm {
  width: 100%;
}

.searchInput {
  display: flex;
  width: 100%;
  position: relative;
}

.searchInput input {
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.searchInput button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.filterControls {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

.filterGroup {
  display: flex;
  align-items: center;
}

.filterGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #555;
}

.filterGroup select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 150px;
}

.clearButton {
  margin-left: auto;
}

.tableWrapper {
  flex: 1;
  overflow: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: white;
}

.reviewsTable {
  width: 100%;
  border-collapse: collapse;
}

.reviewsTable th,
.reviewsTable td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.reviewsTable th {
  background-color: #f9f9f9;
  font-weight: 600;
  color: #555;
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
  padding: 12px 16px;
}

.sortableHeader {
  cursor: pointer;
  position: relative;
  padding-right: 24px !important;
}

.sortIcon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.reviewsTable td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.reviewRow {
  cursor: pointer;
}

.reviewRow:hover {
  background-color: #f0f0f0;
}

.reviewDataCell {
  cursor: pointer;
}

.reviewDataCell:hover {
  color: var(--dark-brown);
}

.actionsHeader {
  width: 80px;
  text-align: center;
}

.actionCell {
  width: 80px;
  text-align: center;
}

.noResults {
  text-align: center;
  padding: 32px;
  color: #666;
}

.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

/* Status styles */
.status-pending {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-approved {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-rejected {
  background-color: #f44336;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

/* Modal Styles */
.modalContent {
  margin-top: 16px;
}

.formGroup {
  margin-bottom: 16px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
}

.formGroup select,
.formGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.formGroup textarea {
  min-height: 100px;
  resize: vertical;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

/* Score rendering styles */
.scoreDisplay {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: bold;
  color: white;
}

.highScore {
  background-color: #4caf50;
}

.mediumScore {
  background-color: #ff9800;
}

.lowScore {
  background-color: #f44336;
}

/* Detailed view styles */
.reviewDetail {
  padding: 24px;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.detailCard {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

.detailSection {
  margin-bottom: 24px;
}

.detailSection h3 {
  margin-top: 0;
  margin-bottom: 16px;
  color: var(--dark-brown);
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.reviewScores {
  display: flex;
  gap: 32px;
  margin-bottom: 24px;
}

.scoreItem {
  text-align: center;
}

.scoreValue {
  font-size: 2rem;
  font-weight: bold;
  color: var(--dark-brown);
}

.scoreLabel {
  font-size: 0.9rem;
  color: #666;
}

.detailGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.detailItem {
  margin-bottom: 12px;
}

.detailLabel {
  font-weight: 500;
  color: #666;
  margin-bottom: 4px;
}

.detailValue {
  color: #333;
}

.commentBox {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin-top: 12px;
}

.actionButtons {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.statusHistory {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.statusItem {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  border-left: 3px solid var(--dark-brown);
}

.statusInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.actionType {
  font-weight: 600;
  color: var(--dark-brown);
}

.statusDate {
  color: #666;
  font-size: 0.9rem;
}

.statusDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.statusModerator {
  font-size: 0.9rem;
  color: #555;
}

.statusNote {
  margin: 8px 0 0 0;
  color: #333;
  font-size: 0.95rem;
}
