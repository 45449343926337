.reviewDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.reviewDetailHeader {
  background-color: white;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--dark-brown);
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 0;
}

.reviewStatus {
  display: flex;
  align-items: center;
}

.reviewDetailContent {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reviewOverview {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reviewInfo {
  flex: 1;
}

.reviewInfo h2 {
  margin: 0 0 8px 0;
  color: var(--dark-brown);
}

.reviewMeta {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  color: #666;
  font-size: 0.9rem;
}

.actionButtons {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.reviewSection {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.reviewSection h3 {
  margin: 0 0 16px 0;
  color: var(--dark-brown);
  font-size: 1.2rem;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.reviewScores {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 24px;
}

.scoreItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.scoreDisplay {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.highScore {
  background-color: #4caf50;
}

.mediumScore {
  background-color: #ff9800;
}

.lowScore {
  background-color: #f44336;
}

.scoreLabel {
  font-weight: 500;
  color: #666;
  text-align: center;
}

.detailGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailLabel {
  font-size: 0.9rem;
  color: #666;
}

.detailValue {
  font-weight: 500;
}

.commentBox {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin-top: 8px;
  white-space: pre-line;
}

.userButton {
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 0.8rem;
}

/* Admin actions section */
.adminActionsSection {
  margin-top: 16px;
}

.adminActionsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.adminActionItem {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  border-left: 4px solid var(--dark-brown);
}

.adminActionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.actionType {
  font-weight: 600;
  color: var(--dark-brown);
}

.actionDate {
  color: #666;
  font-size: 0.9rem;
}

.adminActionDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.adminBy {
  font-size: 0.9rem;
}

.adminNote {
  margin: 8px 0 0;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Loading and error states */
.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

/* Status badges */
.status-pending {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-approved {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-rejected {
  background-color: #f44336;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}
